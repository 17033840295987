import Oidc from 'oidc-client';
import { useloggedUserInfo } from "../stores/UserInfo";
const envType = process.env.VUE_APP_NODE_ENV;
class authService  { 
    
constructor(){
    if (envType=='development')
    {
        this.mgr = new Oidc.UserManager({

            //authority: 'https://devlogin.talygen.devlabs.com',
            authority: 'https://stagelogin.talygen.com',
            // authority: 'https://applogin.talygen.com',
            client_id: 'talygenspa',
            redirect_uri: 'http://localhost:9000/Content/Callback.html',
            response_type: 'id_token token',
            scope: 'openid profile',
            post_logout_redirect_uri: 'http://localhost:9000/',
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            silent_redirect_uri: 'http://localhost:9000/Content/silent-renew.html',
            accessTokenExpiringNotificationTime: 10
        });
    }
    else if (envType=='staging'){
            this.mgr = new Oidc.UserManager({
                authority: 'https://stagelogin.talygen.com',
                client_id: 'talygenspa',
                redirect_uri:'https://stagespa.talygen.com/Content/Callback.html',
                response_type: 'id_token token',
                scope: 'openid profile',
                post_logout_redirect_uri: 'https://stagespa.talygen.com',
                userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                automaticSilentRenew: true,
                silent_redirect_uri: 'https://stagespa.talygen.com/Content/silent-renew.html',
                accessTokenExpiringNotificationTime: 10
        });    
    }
    else if (envType=='qa'){
        this.mgr = new Oidc.UserManager({
            authority: 'https://qatestlogin.talygen.com',
            client_id: 'talygenspa',
            redirect_uri:'https://qatestnewui.talygen.com/Content/Callback.html',
            response_type: 'id_token token',
            scope: 'openid profile',
            post_logout_redirect_uri: 'https://qatestnewui.talygen.com',
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            silent_redirect_uri: 'https://qatestnewui.talygen.com/Content/silent-renew.html',
            accessTokenExpiringNotificationTime: 10
        });
    }
    else{
        this.mgr = new Oidc.UserManager({
            //authority: 'https://devlogin.talygen.devlabs.com',
            authority: 'https://applogin.talygen.com',
            client_id: 'talygenspa',
            redirect_uri:'https://appone.talygen.com/Content/Callback.html',
            response_type: 'id_token token',
            scope: 'openid profile',
            post_logout_redirect_uri: 'https://appone.talygen.com',
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            silent_redirect_uri: 'https://appone.talygen.com/Content/silent-renew.html',
            accessTokenExpiringNotificationTime: 10
    });
}
}
    signIn (returnToUrl) {
        returnToUrl ? this.mgr.signinRedirect({ state: returnToUrl })
            : this.mgr.signinRedirect();
    }
    async authenticate(returnPath) {
        const user = await this.getUser(); //see if the user details are in local storage
        if (!!user) {
            await useloggedUserInfo().setUser(user);
        } else {
            await this.signIn(returnPath);
        }
    }
    async logOut(){
        try {
            this.mgr.signoutRedirect().then( () => {
            });
            this.mgr.clearStaleState()
        }catch(error){
            console.log(error);
        }
    
    }
    async getUser() {
        try {
            return await this.mgr.getUser();
        } catch (err) {
            console.log(err);
        }
    }
}
export default new authService();